.container-1440 {
  width: 100%;
  padding-right: 32px;
  padding-left: 32px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
.container-content {
  padding-left: 82px;
  padding-right: 82px;
  padding-top: 70px;
  padding-bottom: 70px;
}
.border-bottom-faded {
  border-bottom: 1px solid #3131310d;
}
.border-green-bottom {
  border-bottom: 1px solid #415148;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}

.showOnBig {
  display: block;
}
.showOnSmall {
  display: none;
}
.image-on-small {
  width: 100% !important;
  margin-left: 0% !important;
}
.pag > .swiper-pagination {
  padding-top: 30px;
  position: relative;
}
.pag .swiper-pagination-bullet {
  background: #d9d9d94a;
  opacity: 1;
  width: 9px;
  height: 9px;
}
.pag .swiper-pagination-bullet-active {
  background: #e4f222;
}
.green-nav-bar {
  background-color: #12251a !important;
}
.green-nav-bar h2 {
  color: white;
}

.green-nav-bar a {
  color: white !important;
}

.green-nav-bar-button {
  color: white;
}
.gap-30 {
  gap: 6px;
}

.translucent-nav-bar {
  background: rgba(255, 255, 255, 0.3) !important;
}
.translucent-nav-bar a {
  color: #1f1f1f !important;
}
.translucent-nav-bar h2 {
  color: #1f1f1f !important;
}
.image-block {
}
.image-caption {
  display: flex;
  justify-content: center;
}
.custom-spinner {
  width: 50px;
  height: 50px;
  border: 2px solid 1f1f1f;
  border-right-color: transparent;
  animation-duration: 1200ms;
}

.slide-out-right:hover svg {
  -webkit-animation: slide-out-right 0.5s ease-in both;
  animation: slide-out-right 0.5s ease-in both;
}

.slide-out-right::after svg {
  -webkit-transform: translateX(0);
  transform: none;
  opacity: 1;
}
.not-completed-input {
  outline: 1px solid red;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

@media (max-width: 990px) {
  .container-1440 {
    padding-left: 20px;
    padding-right: 22px;
  }
  .container-content {
    padding-left: 20px;
    padding-right: 22px;
    padding-top: 50px;
    padding-bottom: 40px;
  }

  .showOnBig {
    display: none !important;
  }
  .showOnSmall {
    display: block !important;
  }
  .image-on-small {
    width: 80% !important;
    margin-left: 10% !important;
  }

  .project-swiper .swiper-slide {
    display: flex;
    justify-content: center;
    width: fit-content;
  }
  .gap-30 {
    row-gap: 30px;
  }
}

@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: none;
    transform: translateX(0);
    transform-style: preserve-3d;
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(12px);
    transform: translateX(12px);
    transform-style: preserve-3d;
    opacity: 0;
  }
  51% {
    -webkit-transform: translateX(-12px);
    transform: translateX(-12px);
    transform-style: preserve-3d;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    transform-style: preserve-3d;
    opacity: 1;
  }
}

@keyframes slide-out-right {
  0% {
    -webkit-transform: none;
    transform: translateX(0);
    transform-style: preserve-3d;
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(12px);
    transform: translateX(12px);
    transform-style: preserve-3d;
    opacity: 0;
  }
  51% {
    -webkit-transform: translateX(-12px);
    transform: translateX(-12px);
    transform-style: preserve-3d;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    transform-style: preserve-3d;
    opacity: 1;
  }
}

@keyframes search-menu-animation {
  from {
    width: 50px;
  }
  to {
    width: 100%;
  }
}

@keyframes horizontal-scroller {
  from {
    transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    will-change: transform;
  }
  to {
    transform: translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    will-change: transform;
  }
}

/* Cookie consent */
/* body {
  --cc-bg: #fcfbfa;
  --cc-text: #1f1f1f;
  --cc-link: #1f1f1f;
  --cc-btn-primary-bg: rgba(42, 134, 255, 1);
  --cc-btn-primary-text: #1f1f1f;
  --cc-btn-primary-hover-bg: rgba(42, 134, 255, 1);
  --cc-btn-primary-hover-text: #000;
  --cc-btn-primary-border: 0px;
  --cc-btn-secondary-bg: rgba(246, 244, 242, 1);
  --cc-btn-secondary-text: rgba(48, 44, 44, 1);
  --cc-btn-secondary-color: rgba(48, 44, 44, 1);
  --cc-btn-secondary-hover-bg: rgba(246, 244, 242, 1);
  --cc-btn-secondary-hover-text: #fff;
  --cc-toggle-bg-off: #606054;
  --cc-toggle-bg-on: #12251a;
  --cc-toggle-bg-readonly: rgba(96, 96, 84, 0.296);
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #fcfbfa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #fcfbfa;
  --cc-cookie-category-block-bg-hover: #dbe5f9;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e1e7f3;
  --cc-overlay-bg: rgba(230, 235, 255, 0.85);
  --cc-webkit-scrollbar-bg: #ebeff9;
  --cc-webkit-scrollbar-bg-hover: #fcfbfa;
} */

#cs .title {
  font-style: normal;
  font-weight: 400;
  font-family: "Lato", sans-serif;
}

.c-bl .title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  font-family: "Lato", sans-serif;
}

#cc-main .cm__btn {
  border: 0px !important;
  font-family: "Lato", sans-serif;
  font-weight: 500 !important;
}

.cc-link {
  color: #1f1f1f !important;
}

.pm__btn {
  border: 0px !important;
  font-family: "Lato", sans-serif;
  font-weight: 500 !important;
}
